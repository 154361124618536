import react, { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Flex } from "antd"
import {  MyInput, NolabelInputSm } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import './index.css'


const {Title, Text}= Typography

const Register = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    

    const selectBefore = (
        <NolabelInputSm 
            name='code'
            placeholder='+996'
            type='number'
            value={form.getFieldValue("code") || ''}
            className='bg-transparent text-white px-0'
            style={{width: 60}}
        />
      );
    


    return (
        <div className='register-form-cover'>
            <Flex justify="center">
                <Row className="register-form">
                    <Col 
                        md={15} sm={0} xs={0}
                        className="flex-col-align-end brand-bg pt-5"
                    >
                            <Space 
                                direction="vertical" 
                                className="px-5 justify-center height-100"
                                size={0} 
                            >
                                <div className="justify-center">
                                    <Image 
                                        src="/assets/images/logo.png" 
                                        className="w-100" 
                                        preview={false} 
                                        alt="rent-logo"
                                    />
                                </div>
                                
                            </Space>
                    </Col>
                    <Col md={9} sm={24} xs={24} className="bg-white register-form-right-side"
                    >
                        <Space 
                            direction='vertical' 
                            className='px-4 py-4 height-100 justify-center'
                            size={10}
                            >
                                <Image src="/assets/images/logo-sm.png" width={'60px'} />
                            <div>
                                <Title 
                                    level={4} 
                                    className="text-white mt-0"
                                >
                                    Welcome to Najla Relator System!
                                </Title>
                                <Text className="text-gray">
                                    Create your account and start the adventure
                                </Text>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                style={{ width: '100%' }}
                                // onFinish={login}
                                requiredMark={false}
                            >
                                <Row>
                                    <Col span={24}>
                                        <MyInput
                                            autoFocus
                                            name="userName"
                                            label="Username"
                                            required
                                            size={'large'}
                                            message='Please enter username'
                                            placeholder='Enter username'
                                            value={form.getFieldValue("userName") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <MyInput
                                            name="email"
                                            label="Email Address"
                                            required
                                            size={'large'}
                                            message='Please enter email address'
                                            placeholder='Enter email address'
                                            value={form.getFieldValue("email") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <MyInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            required
                                            size={'large'}
                                            message='Please enter password'
                                            placeholder='Enter password'
                                            value={form.getFieldValue("password") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <MyInput
                                            name="phoneNumber"
                                            label="Phone Number"
                                            type='number'
                                            required
                                            addonBefore={selectBefore}
                                            size={'large'}
                                            message='Enter phone number'
                                            value={form.getFieldValue("phoneNumber") || ''}
                                            className='bg-transparent text-white input fs-13'
                                        />
                                    </Col>
                                    <Col span={24} className="center">
                                        <div className="mb-30 margintop-5 w-100">
                                            <Button 
                                                type="primary"
                                                htmlType="submit" 
                                                size="large"
                                                block
                                                loading={loading}
                                                className="fs-14"
                                                >
                                                Signup
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                </Row>
                {/* <Text strong className="pt-3 light-grey-text">
                    Version <span className="brand-color">0.2</span> Beta Release
                </Text> */}
            </Flex>
        </div>
    )
}
export {Register}