import React, { useState } from 'react'
import { Card, Col, Row, Space } from 'antd'
import { ModuleTopHeading, RentDrawer } from '../../components';


const Rent = () => {

    const [ visible, setVisible ] = useState(false)
    return (
        <div>
            
            <Card className='brand-bg radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <ModuleTopHeading name='Rent' onClick={()=>setVisible(true)} />
                        </Space>                       
                    </Col>
                </Row>
            </Card>
            <RentDrawer
                visible={visible}
                onClose={()=> {setVisible(false)}}
            />
        </div>
    )
}

export { Rent }
