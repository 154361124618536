import React, { useState } from 'react'
import { Card, Col, Row, Space } from 'antd'
import { ModuleTopHeading } from '../../components';
import { AddEditExpenseDrawer } from '../../components'

const Expense = () => {
    
    const [ visible, setVisible ] = useState(false)

    return (
        <div>
            <Card className='brand-bg radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <ModuleTopHeading name='Expense' onClick={()=>setVisible(true)} />
                        </Space>                       
                    </Col>
                </Row>
            </Card>
            <AddEditExpenseDrawer 
                visible={visible}
                onClose={()=> {setVisible(false)}}
            />
        </div>
    )
}

export { Expense }
