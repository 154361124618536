import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload, Image} from "antd"
import { MyInput, MyRentDate, MySelect } from "../Forms"

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditBookingDrawer = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={'Add New Booking'}
        onClose={closeDrawer}
        className="brand-bg  border0 drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Save
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Property Name'
                        name="propertyName" 
                        placeholder='e.g denmark valley'
                        size='large'
                        required
                        message='Please choose property name'
                        value={form.getFieldValue("propertyName") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Blue Valley'
                            },
                            {
                                key: 2,
                                name: 'Capital Society'
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="text-white mt-0 mb-3 fw-400">BOOKING DETAILS</Title>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='my-3 width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-12 text-white">Upload csv</Typography.Text>
                            </Dragger>
                        </div>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="or-cs">
                        <Text className="text-white">or</Text>
                    </div>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Guest name'
                        name='guestName'
                        placeholder='e.g John Doe'
                        size='large'
                        required
                        message='Please enter customer name'
                        value={form.getFieldValue("guestName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Guest Contact Number'
                        name='guestContactNumber'
                        placeholder='e.g 23547634'
                        size='large'
                        required
                        message='Please enter guest contact number'
                        value={form.getFieldValue("guestContactNumber") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyRentDate
                        datePicker
                        label="Check-in Date"
                        name='checkinDate'
                        size='large'
                        required
                        message="Please select check-in date"
                        value={form.getFieldValue('checkinDate') || ''}
                        className='bg-transparent text-white border-white'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyRentDate
                        datePicker
                        label="Check-out Date"
                        name='checkoutDate'
                        size='large'
                        required
                        message="Please select Check-out date"
                        value={form.getFieldValue('checkoutDate') || ''}
                        className='bg-transparent text-white border-white'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Number of Nights'
                        name='numberOfNights'
                        placeholder='e.g 3'
                        size='large'
                        required
                        message='Please enter a number'
                        value={form.getFieldValue("numberOfNights") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Total Booking Amount'
                        name='bookingAmount'
                        placeholder='e.g 3000'
                        size='large'
                        required
                        message='Please enter a valid amount'
                        value={form.getFieldValue("bookingAmount") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditBookingDrawer}